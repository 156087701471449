import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import Section from '../components/Section';
import Img from 'gatsby-image';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-height: 100%;

  h1 {
    font-size: 3em;
    // text-align: center;
  }
`;

const Text = styled.div`
  max-width: 640px;
  margin: 0 auto;
`;

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Page>
          <Section>
            <Text>
              <h1>Условия и запись на съемку</h1>
              <h2>Портретная/семейная съёмка</h2>
              <p>Стоимость съёмки 15000 руб.*</p>
              <p>Включает:</p>
              <ul>
                <li>
                  до 2х часов работы в студии или вне ее; студия оплачивается
                  отдельно
                </li>
                <li>100 кадров в обработке (как правило получается больше)</li>
                <li>Делаю деликатную ретушь 15 кадров на ваш выбор</li>
                <li>
                  консультации перед съёмкой, помогаю с идеей, локацией,
                  подбором одежды и внешнего облика
                </li>
                <li>передаю исходники по запросу</li>
              </ul>
              <p>
                Готовность фотографий: в течение 3х недель. Передаю через
                облачный диск.
              </p>

              <p>
                *Портретная съёмка до трёх человек. Если вас больше трёх, то
                стоимость оговаривается индивидуально.
              </p>
              <p> </p>
              <h2>Съёмка мероприятия</h2>

              <p>
                Стоимость съёмки мероприятия: 10000 руб. один час, 15000 руб.
                два часа*
              </p>

              <p>Включает:</p>
              <ul>
                <li>
                  80 кадров в обработке за каждый час (как правило получается
                  больше)
                </li>
                <li>все удачные кадры в цветокоррекции </li>
                <li>
                  консультации перед съёмкой, помогаю с идеей, локацией,
                  подбором одежды и внешнего облика
                </li>
                <li>передаю исходники по запросу</li>
              </ul>
              <p>
                Готовность фотографий: в течение 3х недель. Передаю через
                облачный диск.
              </p>

              <p>
                *Если мероприятие свыше двух часов стоимость оговариваем
                отдельно.
              </p>

              <p>
                Мы можем провести съёмку в студии, на улице или у вас дома.
                Также я могу выехать за пределы города, в этом случае необходимо
                согласовать варианты транспортировки/оплату такси.
              </p>

              <p>
                Для бронирования даты необходимо внести предоплату в размере
                4000 руб. от общей суммы. Это гарантия для вас и для меня, что
                съёмка состоится. В случае отмены съемки из-за заказчика
                предоплата не возвращается, однако есть возможность два раза
                перенести съемку при необходимости.
              </p>

              <p>
                Для записи свяжитесь пожалуйста со мной любым удобным для вас
                способом.
              </p>
              <p>
                <a href="tel:+79031148897">+79031148897</a> (телеграмм, вотсап)
                <br />
                <a href="mailto:saharovata@gmail.com">saharovata@gmail.com</a><br/>
                <a href="https://instagram.com/saharovata">@saharovata</a> Инстаграм
              </p>
            </Text>
          </Section>
        </Page>
      </Layout>
    );
  }
}
